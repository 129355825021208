import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConfigState } from '@interfaces';

export const configStateSelector = createFeatureSelector<ConfigState>('config');

export const getTimezonesData = (state: ConfigState): string[] => state.timezones.data;
export const getTimezonesLoading = (state: ConfigState): boolean => state.timezones.isLoading;
export const getTimezonesError = (state: ConfigState): string => state.timezones.error;

export const selectTimezones = createSelector(configStateSelector, getTimezonesData);
export const selectTimezonesLoading = createSelector(configStateSelector, getTimezonesLoading);
export const selectTimezonesError = createSelector(configStateSelector, getTimezonesError);


