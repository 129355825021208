import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectSettings, SettingsState } from '@interfaces';

export const settingsStateSelector = createFeatureSelector<SettingsState>('settings');

export const getProjectSettingsData = (state: SettingsState): ProjectSettings => state.projectSettings.data;
export const getProjectSettingsLoading = (state: SettingsState): boolean => state.projectSettings.isLoading;
export const getProjectSettingsError = (state: SettingsState): string => state.projectSettings.error;

export const selectProjectSettings = createSelector(settingsStateSelector, getProjectSettingsData);
export const selectProjectSettingsLoading = createSelector(settingsStateSelector, getProjectSettingsLoading);
export const selectProjectSettingsError = createSelector(settingsStateSelector, getProjectSettingsError);

export const getAllSettingsData = (state: SettingsState) => state.settings.data;
export const getAllSettingsLoading = (state: SettingsState): boolean => state.settings.isLoading;
export const getAllSettingsError = (state: SettingsState): string => state.settings.error;

export const selectAllSettings = createSelector(settingsStateSelector, getAllSettingsData);
export const selectAllSettingsLoading = createSelector(settingsStateSelector, getAllSettingsLoading);
export const selectAllSettingsError = createSelector(settingsStateSelector, getAllSettingsError);
