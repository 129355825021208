import { Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '@services/language.service';

@Pipe({
  name: 'translation',
})
export class TranslationPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(object: any, defaultTranslateProperty = 'name'): string {
    const { currentLanguage } = this.languageService;
    return object?.translation?.data[currentLanguage] || object?.[defaultTranslateProperty];
  }
}
