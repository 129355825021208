/* eslint-disable */
import * as Types from '../../../../../graphql-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetSupplierLocationIdQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
}>;


export type GetSupplierLocationIdQuery = { __typename?: 'Query', supplierUsers?: Array<{ __typename?: 'SupplierUser', supplierLocationId?: any | null }> | null };

export const GetSupplierLocationIdDocument = gql`
    query getSupplierLocationId($userId: UUID) {
  supplierUsers(condition: {userId: $userId}) {
    supplierLocationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSupplierLocationIdGQL extends Apollo.Query<GetSupplierLocationIdQuery, GetSupplierLocationIdQueryVariables> {
    document = GetSupplierLocationIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }