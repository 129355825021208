import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from '@services/utils/config.service';
import { GetProjectSettingsResponse, GetGeneralSettingsResponse } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly settingsApiUrl = `${this.config.apiUrl}/settings`;

  constructor(private http: HttpClient, private config: ConfigService) {}

  public getProjectSettings(): Observable<GetProjectSettingsResponse> {
    return this.http.get<GetProjectSettingsResponse>(`${this.settingsApiUrl}/project`);
  }

  public getAllSettings(): Observable<GetGeneralSettingsResponse> {
    return this.http.get<GetGeneralSettingsResponse>(`${this.settingsApiUrl}`);
  }
}
