import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AUTH_TOKEN_KEY } from '@constants';

import { environment } from '@env/environment';
import { CookieStorageService } from '@services/data-integration/cookie-storage.service';
import { AlertService } from '@services/utils/alert.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthFacade } from '@store';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private storageService: CookieStorageService,
    private authFacade: AuthFacade,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storageService.get(AUTH_TOKEN_KEY);
    const isPropergateUrl = req.url.startsWith(environment.apiUrl) || req.url.startsWith(environment.apiNgUrl);

    const isGraphqlRequest = req.url.includes(`${environment.apiNgUrl}/graphql`);

    if (isPropergateUrl && !isGraphqlRequest) {
      req = req.clone({
        setHeaders: {
          Authorization: String(token),
        },
        withCredentials: true,
      });
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        let message = err.message;

        if (typeof err.error.message === 'string') {
          message = err.error.message;
        } else if (err.error.message?.length > 0) {
          message = err.error.message[0].message;
        }

        if (message) {
          this.alertService.alert(message);
        }

        if ([401, 403].includes(err.status)) {
          this.authFacade.logout();
        }

        return throwError(err);
      }),
    );
  }
}
