import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ConfigService } from '@services/utils/config.service';
import { LoginResponse, Response, User } from '@shared/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SupplierFacade } from 'src/app/store/supplier-store/supplier-facade';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authApiUrl = `${this.config.apiUrl}/users`;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private jwtHelperService: JwtHelperService,
    private supplierFacade: SupplierFacade,
  ) {}

  public login(email: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.authApiUrl}/login`, { email, password });
  }

  public isAuthenticated(): boolean {
    return !this.jwtHelperService.isTokenExpired();
  }

  public getCurrentUser(): Observable<User> {
    return this.http.get(`${this.authApiUrl}/get/me`).pipe(
      map(({ data }: Response<{ user: User }>) => {
        this.supplierFacade.getCurrentSupplierLocationId(data.user.id);
        return data.user;
      }),
    );
  }
}
