import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from '@services/data-integration/auth.service';
import { AuthFacade, UserFacade } from '@store';
import { UserRoleTypes } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class SubcontractorGuard  {
  constructor(
    private authService: AuthService,
    private authFacade: AuthFacade,
    private userFacade: UserFacade,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> | boolean {
    const isAuthenticated = this.authService.isAuthenticated();

    if (isAuthenticated) {
      return this.authService.getCurrentUser().pipe(
        map(user => {
          if (user?.role.type === UserRoleTypes.SUBCONTRACTOR) {
            return true;
          }

          this.router.navigate(['/supplier']);
          return false;
        }),
      );
    }

    return false;
  }
}
