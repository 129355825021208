import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@services/data-integration/auth.service';
import { LanguageService } from '@services/language.service';
import * as UserActions from './user-actions';
import { SettingsFacade } from '@store';

@Injectable()
export class UserEffects {
  constructor(
    private authService: AuthService,
    private languageService: LanguageService,
    private actions$: Actions,
    private settingsFacade: SettingsFacade,
  ) {}

  public getUserRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetUserRequestAction),
      switchMap(() => {
        return this.authService.getCurrentUser().pipe(
          map((user) => {
            this.settingsFacade.getAllSettings();
            return UserActions.GetUserSuccessAction({ user });
          }),
          catchError((err: HttpErrorResponse) => {
            return of(UserActions.GetUserFailAction({ error: err.error }));
          }),
        );
      }),
    ),
  );

  public getUserSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.GetUserSuccessAction),
        tap(({ user }) => {
          this.languageService.changeLanguage(user.language);
        }),
      ),
    { dispatch: false },
  );
}
