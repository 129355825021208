import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GetSupplierLocationIdGQL } from 'src/app/supplier/graphql/queries/get-supplier-location-id/get-supplier-location-id.generated';

import * as SupplierActions from './supplier-actions';

@Injectable()
export class SupplierEffects {
  public getSupplierLocationId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierActions.GetSupplierLocationId),
      switchMap(({ userId }) => {
        return this.getSupplierLocationIdGQL.fetch({ userId }).pipe(
          map((response) =>
            SupplierActions.GetSupplierLocationIdSuccess({
              supplierLocationId: response.data.supplierUsers[0].supplierLocationId,
            }),
          ),
          catchError((err: HttpErrorResponse) => [SupplierActions.GetSupplierLocationIdFail({ error: err.error })]),
        );
      }),
    ),
  );

  constructor(private actions$: Actions, private getSupplierLocationIdGQL: GetSupplierLocationIdGQL) {}
}
