import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { RootStoreModule } from '@store';
import { CoreRoutingModule } from './core-routing.module';
import { HttpConfigInterceptor } from '@interceptors/http-config.interceptor';
import { AUTH_TOKEN_KEY } from '@constants';
import { CookieStorageService } from '@services/data-integration/cookie-storage.service';

const ngZorroConfig: NzConfig = {
  button: { nzSize: 'large' },
  message: { nzDuration: 5000 },
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    CoreRoutingModule,
    RootStoreModule,
    StoreDevtoolsModule.instrument({
      name: 'Propergate App',
      maxAge: 25,
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: (storageService: CookieStorageService) => ({
          tokenGetter: () => storageService.get(AUTH_TOKEN_KEY),
        }),
        deps: [CookieStorageService],
      },
    }),
  ],
  exports: [CoreRoutingModule],
  providers: [
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  ],
})
export class CoreModule {}
