import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  set(cookieKey: string, cookieValue: any, expires: string = null): void {
    const domain = location.hostname.replace(/^[^.]*/, '').split('.').slice(-2).join('.');

    let cookie = `${cookieKey}=${cookieValue}; path=/; domain=${domain.length > 0 ? `.${domain}` : ''}`;
    if (expires) {
      cookie += `; expires=${expires}`;
    }
    this.document.cookie = cookie;
  }

  get(cookieKey: string): any {
    const match = this.document.cookie.match(
      new RegExp(`(^| )${cookieKey}=([^;]+)`),
    );
    return match ? match[2] : null;
  }

  remove(cookieKey: string): void {
    const expires = new Date().toUTCString();
    this.set(cookieKey, '', expires);
  }
}
