import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzProgressModule } from 'ng-zorro-antd/progress';

const antModules = [
  NzInputModule,
  NzFormModule,
  NzIconModule,
  NzButtonModule,
  NzAffixModule,
  NzCheckboxModule,
  NzGridModule,
  NzInputNumberModule,
  NzMenuModule,
  NzMessageModule,
  NzRadioModule,
  NzSelectModule,
  NzTimePickerModule,
  NzUploadModule,
  NzTabsModule,
  NzSwitchModule,
  NzTagModule,
  NzToolTipModule,
  NzCollapseModule,
  NzPopconfirmModule,
  NzPopoverModule,
  NzSliderModule,
  NzDropDownModule,
  NzListModule,
  NzEmptyModule,
  NzSpinModule,
  NzDatePickerModule,
  NzModalModule,
  NzProgressModule,
];

@NgModule({
  imports: [CommonModule, ...antModules],
  exports: [...antModules],
})
export class NgZorroModule {}
