import { createAction, props } from '@ngrx/store';

enum SupplierActionTypes {
  GET_SUPPLIER_LOCATION_ID = '[SUPPLIER] Get Supplier Location Id',
  GET_SUPPLIER_LOCATION_ID_SUCCESS = '[SUPPLIER] Get Supplier Location Id Success',
  GET_SUPPLIER_LOCATION_ID_FAIL = '[SUPPLIER] Get Supplier Location Id Fail',
}

export const GetSupplierLocationId = createAction(
  SupplierActionTypes.GET_SUPPLIER_LOCATION_ID,
  props<{ userId: string }>(),
);

export const GetSupplierLocationIdSuccess = createAction(
  SupplierActionTypes.GET_SUPPLIER_LOCATION_ID_SUCCESS,
  props<{ supplierLocationId: string }>(),
);

export const GetSupplierLocationIdFail = createAction(
  SupplierActionTypes.GET_SUPPLIER_LOCATION_ID_FAIL,
  props<{ error: string }>(),
);
