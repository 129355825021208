import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState, User } from '@interfaces';

export const getCurrentUser = (state: UserState): User => state.user.data;
export const getIsLoading = (state: UserState): boolean => state.user.isLoading;
export const getLoadingError = (state: UserState): string => state.user.error;

export const userStateSelector = createFeatureSelector<UserState>('user');

export const selectIsLoading = createSelector(userStateSelector, getIsLoading);
export const selectLoadingError = createSelector(userStateSelector, getLoadingError);
export const selectCurrentUser = createSelector(userStateSelector, getCurrentUser);
