import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iterable',
})
export class IterablePipe implements PipeTransform {
  transform(max: number, min = 0): number[] {
    return Array(max - min)
      .fill(0)
      .map((_, idx) => min + idx);
  }
}
