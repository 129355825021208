import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthStoreModule } from './auth-store/auth-store.module';
import { UserStoreModule } from './user-store/user-store.module';
import { SettingsStoreModule } from './settings-store/settings-store.module';
import { ConfigStoreModule } from './config-store/config-store.module';
import { SupplierStoreModule } from './supplier-store/supplier-store.module';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    AuthStoreModule,
    UserStoreModule,
    ConfigStoreModule,
    SettingsStoreModule,
    SupplierStoreModule,
  ],
})
export class RootStoreModule {}
