import { Injectable } from '@angular/core';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private message: NzMessageService) {}

  public success(message: string): NzMessageRef {
    return this.message.success(message);
  }

  public warning(message: string): NzMessageRef {
    return this.message.warning(message);
  }

  public alert(message: string): NzMessageRef {
    return this.message.error(message);
  }
}
