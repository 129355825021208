import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@services/data-integration/auth.service';
import * as AuthActions from './auth-actions';
import { CookieStorageService } from '@services/data-integration/cookie-storage.service';
import { AUTH_TOKEN_KEY } from '@constants';

@Injectable()
export class AuthEffects {
  constructor(
    private authService: AuthService,
    private storageService: CookieStorageService,
    private actions$: Actions,
    private router: Router,
  ) {}

  public loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoginAction),
      switchMap((action) => {
        return this.authService.login(action.payload.email, action.payload.password).pipe(
          map((response) => {
            this.storageService.set(AUTH_TOKEN_KEY, response.data.token);
            this.router.navigate(['subcontractor']);

            // TODO handle inactive password
            return AuthActions.LoginSuccessAction();
          }),
          catchError((err: HttpErrorResponse) => {
            return of(AuthActions.LoginFailAction({ error: err.error }));
          }),
        );
      }),
    ),
  );

  public logoutEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LogoutAction),
        tap(() => {
          this.storageService.remove(AUTH_TOKEN_KEY);

          this.router.navigate(['/login']);
        }),
      ),
    { dispatch: false },
  );
}
