import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  timezone: string;

  setTimezone(timezone: string): void {
    this.timezone = timezone;
    dayjs.tz.setDefault(timezone);
  }
}
