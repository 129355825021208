
  export const environment = {
    production: true,
    version: '(env.dev)',
    apiUrl: 'https://oracle.propergate.app/api',
    apiNgUrl: 'https://api.oracle.propergate.app',
    appUrl: 'https://oracle.propergate.app',
    appNgUrl: 'https://oracle2.propergate.app',
    socketUrl: '',
    socketPath: '/api/socket.io',
    defaultTokenKey: 'token',
    defaultLanguage: 'en',
    enableFloorEditor: false,
    clientShortname: 'ORACLE',
    tasksUrl: 'undefined',
    mspIntegrationServiceUrl: 'undefined',
    procoreIntegrationUrl: 'undefined',
    gcpApiKey: 'AIzaSyAZb_iQc3189qwYf-iqKOtLgeX01-UHR40',
    gcpProjectId: 'stage-286013',
    clientLogoUrl: 'undefined'
  };