import { SettingsState } from '@interfaces';

export const settingsInitialState: SettingsState = {
  projectSettings: {
    data: null,
    isLoading: false,
    error: null,
  },
  settings: {
    data: null,
    isLoading: false,
    error: null,
  },
};
