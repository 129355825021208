import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import dayjs from 'dayjs';
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translocoService: TranslocoService) {}

  get currentLanguage(): string {
    return this.translocoService.getActiveLang();
  }

  public changeLanguage(language: string): void {
    this.translocoService.setActiveLang(language);
    import(`dayjs/locale/${language}`).then(() => {
      dayjs.locale(language);
    });
  }
}
