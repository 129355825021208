import { createReducer, on } from '@ngrx/store';

import * as SettingsActions from './settings-actions';
import { settingsInitialState } from './settings-state';
import { GeneralSettings, ProjectSettings } from '@interfaces';

export const settingsReducer = createReducer(
  settingsInitialState,
  on(SettingsActions.GetProjectSettingsAction, state => {
    return {
      ...state,
      projectSettings: {
        data: null,
        isLoading: true,
        error: null,
      },
    };
  }),
  on(SettingsActions.GetProjectSettingsSuccessAction, (state, data: ProjectSettings) => {
    return {
      ...state,
      projectSettings: {
        data,
        isLoading: false,
        error: null,
      },
    };
  }),
  on(SettingsActions.GetProjectSettingsFailAction, (state, payload: { error: string }) => {
    return {
      ...state,
      projectSettings: {
        data: null,
        isLoading: false,
        error: payload.error,
      },
    };
  }),
  on(SettingsActions.GetAllSettingsAction, state => {
    return {
      ...state,
      settings: {
        data: null,
        isLoading: true,
        error: null,
      },
    };
  }),
  on(SettingsActions.GetAllSettingsSuccessAction, (state, data: GeneralSettings) => {
    return {
      ...state,
      settings: {
        data,
        isLoading: false,
        error: null,
      },
    };
  }),
  on(SettingsActions.GetAllSettingsFailAction, (state, payload: { error: string }) => {
    return {
      ...state,
      settings: {
        data: null,
        isLoading: false,
        error: payload.error,
      },
    };
  }),
);
