import { Injectable } from '@angular/core';

import { UserState } from '@interfaces';
import { Store } from '@ngrx/store';
import { GetUserRequestAction } from './user-actions';
import { selectCurrentUser, selectIsLoading, selectLoadingError } from './user-selectors';

@Injectable()
export class UserFacade {
  public currentUser$ = this.store.select(selectCurrentUser);
  public isLoading$ = this.store.select(selectIsLoading);
  public loadingError$ = this.store.select(selectLoadingError);

  constructor(private store: Store<UserState>) {}

  public getCurrentUser(): void {
    this.store.dispatch(GetUserRequestAction());
  }
}
