import { createReducer, on } from '@ngrx/store';

import * as UserActions from './user-actions';
import { userInitialState } from './user-state';

export const userReducer = createReducer(
  userInitialState,
  on(UserActions.GetUserSuccessAction, (state, { user }) => {
    return {
      ...state,
      user: {
        data: user,
        isLoading: false,
        error: null,
      },
    };
  }),
  on(UserActions.GetUserFailAction, (state, payload) => {
    return {
      ...state,
      user: {
        data: null,
        isLoading: false,
        error: payload.error,
      },
    };
  }),
);
