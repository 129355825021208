/* eslint-disable max-classes-per-file */
import { registerLocaleData, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import pl from '@angular/common/locales/pl';
import de from '@angular/common/locales/de';
import ja from '@angular/common/locales/ja';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { LayoutsModule } from '@layouts/layouts.module';
import { EffectsModule } from '@ngrx/effects';
import { AppInitializerService } from '@services/app-initializer.service';
import { en_US, NZ_I18N, pl_PL, de_DE, ja_JP } from 'ng-zorro-antd/i18n';
import { AppComponent } from './app.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { CoreModule } from './core/core.module';
import { GlobalErrorHandler } from './core/global-error-handler';
import { GraphQLModule } from './graphql.module';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoService } from '@ngneat/transloco';
import { DateTimeService } from '@services/date-time.service';

registerLocaleData(en);
registerLocaleData(pl);
registerLocaleData(de);
registerLocaleData(ja);

dayjs.extend(timezone);
dayjs.extend(utc);

export class DynamicLocaleId extends String {
  constructor(protected service: TranslocoService) {
    super();
  }

  override toString() {
    return this.service.getActiveLang() || 'en';
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    EffectsModule.forRoot([]),
    LayoutsModule,
    GraphQLModule,
    TranslocoRootModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService) => () => configService.loadAppSettings(),
      multi: true,
      deps: [AppInitializerService],
    },
    {
      provide: ErrorHandler,
      useClass: environment.production ? GlobalErrorHandler : ErrorHandler,
    },
    {
      provide: NZ_I18N,
      useFactory: ({ service }) => {
        switch (service.getActiveLang()) {
          case 'en':
            return en_US;
          case 'pl':
            return pl_PL;
          case 'de':
            return de_DE;
          case 'ja':
            return ja_JP;
          default:
            return en_US;
        }
      },
      deps: [LOCALE_ID],
    },
    {
      provide: LOCALE_ID,
      deps: [TranslocoService],
      useClass: DynamicLocaleId,
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useFactory: (dateTimeService: DateTimeService) => {
        const GMTzone = dayjs().tz(dateTimeService.timezone).offsetName();
        return { timezone: GMTzone };
      },
      deps: [DateTimeService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
