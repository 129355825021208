import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';
import { SubcontractorGuard } from '@guards/subcontractor.guard';
import { SupplierGuard } from '@guards/supplier.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'subcontractor',
    loadChildren: () => import('../subcontractor/subcontractor.module').then((m) => m.SubcontractorModule),
    data: {
      breadcrumb: 'breadcrumb.prefabricated',
    },
    canActivate: [AuthGuard, SubcontractorGuard],
  },
  {
    path: 'supplier',
    loadChildren: () => import('../supplier/supplier.module').then((m) => m.SupplierModule),
    canActivate: [AuthGuard, SupplierGuard],
  },
  {
    path: 'order-confirmation',
    loadChildren: () =>
      import('../features/order-confirmation/order-confirmation.module').then((m) => m.OrderConfirmationModule),
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
