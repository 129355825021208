import { createReducer, on } from '@ngrx/store';

import * as ConfigActions from './config-actions';
import { configInitialState } from './config-state';

export const configReducer = createReducer(
  configInitialState,
  on(ConfigActions.GetTimezonesAction, state => {
    return {
      ...state,
      timezones: {
        data: null,
        isLoading: true,
        error: null,
      },
    };
  }),
  on(ConfigActions.GetTimezonesSuccessAction, (state, data: string[]) => {
    return {
      ...state,
      timezones: {
        data,
        isLoading: false,
        error: null,
      },
    };
  }),
  on(ConfigActions.GetTimezonesFailAction, (state, payload: { error: string }) => {
    return {
      ...state,
      timezones: {
        data: null,
        isLoading: false,
        error: payload.error,
      },
    };
  }),
);
