import { createAction, props } from '@ngrx/store';

enum ConfigActionsTypes {
  GET_TIMEZONES = '[PPG] Get Timezones',
  GET_TIMEZONES_SUCCESS = '[PPG] Get Timezones Success',
  GET_TIMEZONES_FAIL = '[PPG] Get Timezones Fail',
}

export const GetTimezonesAction = createAction(
  ConfigActionsTypes.GET_TIMEZONES,
);

export const GetTimezonesSuccessAction = createAction(
  ConfigActionsTypes.GET_TIMEZONES_SUCCESS,
  props<any>(),
);

export const GetTimezonesFailAction = createAction(
  ConfigActionsTypes.GET_TIMEZONES_FAIL,
  props<{ error: string }>(),
);
