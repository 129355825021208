import { createReducer, on } from '@ngrx/store';

import * as AuthActions from './auth-actions';
import { authInitialState } from './auth-state';

export const authReducer = createReducer(
  authInitialState,
  on(AuthActions.SetUserLogged, (state) => {
    return {
      ...state,
      login: {
        ...state.login,
        isUserLogged: true,
      },
    };
  }),
  on(AuthActions.LoginAction, (state) => {
    return {
      ...state,
      login: {
        isUserLogged: false,
        isLoading: true,
        error: null,
      },
    };
  }),
  on(AuthActions.LoginSuccessAction, (state) => {
    return {
      ...state,
      login: {
        ...state.login,
        isUserLogged: true,
        isLoading: false,
      },
    };
  }),
  on(AuthActions.LoginFailAction, (state, payload) => {
    return {
      ...state,
      login: {
        isUserLogged: false,
        isLoading: false,
        error: payload.error,
      },
    };
  }),
  on(AuthActions.LogoutAction, (state) => {
    return {
      ...state,
      login: {
        isUserLogged: false,
        isLoading: false,
        error: null,
      },
    };
  }),
);
