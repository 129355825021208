import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  transform(value: unknown): unknown {
    return value || value === 0 ? value : '-';
  }
}
