import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@services/data-integration/auth.service';
import { AuthFacade } from '@store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private authService: AuthService,
    private authFacade: AuthFacade,
    private router: Router,
  ) {}

  canActivate(): boolean {
    const isAuthenticated = this.authService.isAuthenticated();

    if (!isAuthenticated) {
      this.router.navigate(['/login']);
      return isAuthenticated;
    }

    this.authFacade.setUserLogged();
    return isAuthenticated;
  }
}
