import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { SettingsState } from '@interfaces';
import { GetAllSettingsAction, GetProjectSettingsAction } from './settings-actions';
import {
  selectAllSettings,
  selectAllSettingsError,
  selectAllSettingsLoading,
  selectProjectSettings,
  selectProjectSettingsError,
  selectProjectSettingsLoading,
} from './settings-selectors';

@Injectable()
export class SettingsFacade {
  public projectSettings$ = this.store.select(selectProjectSettings);
  public projectSettingsLoading$ = this.store.select(selectProjectSettingsLoading);
  public projectSettingsError$ = this.store.select(selectProjectSettingsError);

  public allSettings$ = this.store.select(selectAllSettings);
  public allSettingsLoading$ = this.store.select(selectAllSettingsLoading);
  public allSettingsError$ = this.store.select(selectAllSettingsError);

  constructor(private store: Store<SettingsState>) {}

  public getProjectSettings(): void {
    this.store.dispatch(GetProjectSettingsAction());
  }

  public getAllSettings(): void {
    this.store.dispatch(GetAllSettingsAction());
  }
}
