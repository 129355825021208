import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { settingsReducer } from './settings-reducer';
import { SettingsEffects } from './settings-effects';
import { SettingsFacade } from './settings-facade';

@NgModule({
  imports: [
    StoreModule.forFeature('settings', settingsReducer),
    EffectsModule.forFeature([ SettingsEffects ]),
  ],
  providers: [
    SettingsEffects,
    SettingsFacade,
  ],
})
export class SettingsStoreModule { }
