import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as SettingsActions from './settings-actions';
import { SettingsService } from '@services/data-integration/settings.service';

@Injectable()
export class SettingsEffects {
  constructor(private actions$: Actions, private settingsService: SettingsService) {}

  public getProjectSettingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.GetProjectSettingsAction),
      switchMap(() => {
        return this.settingsService.getProjectSettings().pipe(
          map((response) => SettingsActions.GetProjectSettingsSuccessAction(response.projectSettings)),
          catchError((err: HttpErrorResponse) => [SettingsActions.GetProjectSettingsFailAction({ error: err.error })]),
        );
      }),
    ),
  );

  public getAllSettingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.GetAllSettingsAction),
      switchMap(() => {
        return this.settingsService.getAllSettings().pipe(
          map((response) => SettingsActions.GetAllSettingsSuccessAction(response.generalSettings)),
          catchError((err: HttpErrorResponse) => [SettingsActions.GetAllSettingsFailAction({ error: err.error })]),
        );
      }),
    ),
  );
}
