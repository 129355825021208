import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';

import { SettingsFacade } from '@store';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(private settingsFacade: SettingsFacade, private dateTimeService: DateTimeService) {}

  loadAppSettings() {
    return new Promise((resolve) => {
      this.settingsFacade.getProjectSettings();
      this.settingsFacade.projectSettings$.pipe(filter(Boolean), take(1)).subscribe(({ timezone }) => {
        this.dateTimeService.setTimezone(timezone);
        resolve(true);
      });
    });
  }
}
