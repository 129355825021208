import { createAction, props } from '@ngrx/store';

import { ProjectSettings } from '@interfaces';

enum SettingsActionsTypes {
  GET_PROJECT_SETTINGS = '[PPG] Get Project Settings',
  GET_PROJECT_SETTINGS_SUCCESS = '[PPG] Get Project Settings Success',
  GET_PROJECT_SETTINGS_FAIL = '[PPG] Get Project Settings Fail',
  GET_ALL_SETTINGS = '[PPG] Get All Settings',
  GET_ALL_SETTINGS_SUCCESS = '[PPG] Get All Settings Success',
  GET_ALL_SETTINGS_FAIL = '[PPG] Get All Settings Fail',
}

export const GetProjectSettingsAction = createAction(
  SettingsActionsTypes.GET_PROJECT_SETTINGS,
);

export const GetProjectSettingsSuccessAction = createAction(
  SettingsActionsTypes.GET_PROJECT_SETTINGS_SUCCESS,
  props<ProjectSettings>(),
);

export const GetProjectSettingsFailAction = createAction(
  SettingsActionsTypes.GET_PROJECT_SETTINGS_FAIL,
  props<{ error: string }>(),
);

export const GetAllSettingsAction = createAction(
  SettingsActionsTypes.GET_ALL_SETTINGS,
);

export const GetAllSettingsSuccessAction = createAction(
  SettingsActionsTypes.GET_ALL_SETTINGS_SUCCESS,
  props<any>(),
);

export const GetAllSettingsFailAction = createAction(
  SettingsActionsTypes.GET_ALL_SETTINGS_FAIL,
  props<{ error: string }>(),
);
